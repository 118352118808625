import React from 'react';
import { NextSeo } from 'next-seo';
import withApolloProvider from '~/lib/apollo/with-apollo-provider';
import LiveStreamLandingPage from '~/pages/LiveStreamLandingPage';

import PipedriveChat from '~/components/PipedriveChat';

const IndexPage = () => {
    const title = `Digital Marketplace for Agent | Realty.com.au`;
    const metaDescription =
        'Access pre-market, off market and passive proeprties in our private buyers agent marketplace. Instant AI Property Alerts.';

    return (
        <>
            <NextSeo
                title={title}
                description={metaDescription}
                canonical="https://www.realty.com.au"
                openGraph={{
                    type: 'product',
                    title,
                    description: metaDescription,
                    locale: 'en_US',
                    url: `https://www.realty.com.au`,
                    images: [
                        {
                            url: 'https://au.cdn.realty.com.au/assets/realtycomau_home_new.png',
                            width: 1200,
                            height: 628,
                            alt: 'Digital Marketplace for Agent | Realty.com.au',
                        },
                    ],
                }}
            />

            <PipedriveChat companyId={4209319} playbookUuid="ee9d5577-fa3c-413c-890b-a0dca765aa99" />

            <LiveStreamLandingPage />
        </>
    );
};

export default withApolloProvider(IndexPage);
