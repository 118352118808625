import React from 'react';
import Script from 'next/script';

type Props = {
    companyId: number;
    playbookUuid: string;
};

const PipedriveChat: React.FC<Props> = (props) => {
    const { companyId, playbookUuid } = props;
    return (
        <>
            <Script id="pipedrive-config" strategy="afterInteractive">
                {/* eslint-disable-next-line max-len */}
                {`window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: ${companyId},playbookUuid: '${playbookUuid}',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();`}
            </Script>
            <Script id="pipedrive-script" src="https://leadbooster-chat.pipedrive.com/assets/loader.js" async />
        </>
    );
};

export default PipedriveChat;
